import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "../style/list-project.less";

export default function ProjectGrid({ data }) {
  const edges = data.allMarkdownRemark.edges

  return (
    <div className="row">
      {edges.map(p =>
        <div key={p.node.id} className="item col s12">
          <div className="row flex">
            <div className="col m6 image">
              <GatsbyImage image={p.node.frontmatter.image.childImageSharp.gatsbyImageData} alt=""/>
              <Link
                to={p.node.fields.slug}
                title={p.node.frontmatter.title}
                aria-label={p.node.frontmatter.title}
                className="overlay-link"
                style={{ opacity: 0 }}
              >
                {p.node.frontmatter.title}
              </Link>
            </div>
            <div className="col m6 content">
              <h2 className="text-primary pseudo-divider">
                <Link
                  to={p.node.fields.slug}
                  title={p.node.frontmatter.title}
                  aria-label={p.node.frontmatter.title}
                >
                  {p.node.frontmatter.title}
                </Link>
              </h2>
              <p className="text-tertiary">
                {p.node.frontmatter.description}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
