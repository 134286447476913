import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ProjectGrid from "../components/project-grid";
import SectionTitle from "../components/sectiontitle";
import Pagination from "../components/pagination";
import Seo from "../components/seo";

export default function ProjectIndex({ data, pageContext }) {
  return (
    <Layout>
      <Seo lang="en" title="Open Source" />
      {data.allMarkdownRemark.edges.length > 0
        ? <section id="project" className="container">
          <div className="section-title">
            <SectionTitle title="Projects" />
          </div>
          <ProjectGrid data={data} />
          <Pagination
            pageContext={pageContext}
            type="project"
          />
        </section>
        : <>
        </>
      }
    </Layout>
  );
}

export const query = graphql`
query ($skip: Int!, $limit: Int!) {
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/project/"}}
    sort: {fields: [frontmatter___date], order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          description
          date
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              id
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
